import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import SEO from "../components/seo";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";

const axios = require("axios").default;

function CurriculoPage() {
    return (
        <>
            <SEO title="Envie seu Currículo" />
            <section className="py-8 px-12 lg:py-16 lg:px-6">
                <div className="container mx-auto  text-azul_base ">
                    <h1 className="leading-none text-2xl font-bold mb-4 lg:text-4xl lg:mb-12">Envie seu Currículo</h1>
                    <div className="flex flex-col lg:flex-row lg:justify-start">
                        <div className="lg:w-1/2">
                            <p className="text-base text-preto leading-none mb-6 lg:text-xl lg:leading-tight">
                                Para que possamos conhecer o seu perfil, envie o formulário ao lado com uma breve apresentação e o currículo em anexo.
                            </p>
                            <p className="text-azul_base text-sm mb-6 lg:text-lg">
                                <strong>
                                    <FontAwesomeIcon icon={faWhatsapp} />
                                </strong> <a href="https://encurtador.com.br/mIM47">(11)5585-0112</a>
                            </p>
                            <p className="text-azul_base text-sm mb-6 lg:text-lg">
                                <strong>Endereço</strong>: Av. Jabaquara, 1336 - Mirandópolis, São Paulo - SP, 04046002
                            </p>
                            <p className="text-azul_base text-sm lg:text-lg">
                                <strong>Funcionamento</strong>: Seg-Sex 9:00-17:00 Sábados 9:00-12:00
                            </p>
                        </div>
                        <div className="text-base mt-4 lg:text-lg lg:w-2/3 lg:mt-0 lg:ml-12 lg:flex lg:flex-col">
                            <Formik
                                initialValues={{ email: "", cv: "", mensagem: "", accept: false }}
                                onSubmit={(values, actions) => {
                                    var formData = new FormData();
                                    formData.append('email', values.email);
                                    formData.append('cv', values.cv);
                                    formData.append('mensagem', values.mensagem);
                                    return axios
                                        .post("/sendcv.php", formData)
                                        .then(res => {
                                            console.log(res);
                                            if (res.data.success) {
                                                actions.resetForm();
                                                document.getElementById("success").innerText =
                                                "Currículo enviado com sucesso";
                                                setTimeout(() => {
                                                    document.getElementById("success").innerText = "";
                                                }, 5000);
                                            } else {
                                                actions.setFieldError(
                                                    "mensagem",
                                                    res.data.message
                                                );
                                            }
                                        })
                                        .catch(err => {
                                            actions.setFieldError(
                                                "mensagem",
                                                "Ocorreu um erro ao enviar seu currículo, por favor tente novamente"
                                            );
                                        });
                                }}
                                validationSchema={Yup.object().shape({
                                    email: Yup.string().email("este não é um e-mail válido").required("por favor insira seu e-mail"),
                                    cv: Yup.string().required("o currículo é obrigatório"),
                                    mensagem: Yup.string().required("o campo mensagem é obrigatório"),
                                    accept: Yup.boolean().oneOf([true], 'Leia e Aceite as Políticas de Privacidade antes de enviar'),
                                })}>
                                {({
                                    values,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue,
                                }) => {
                                    return (
                                        <form className="flex flex-col" onSubmit={handleSubmit}>
                                            <label className="text-preto font-bold mb-1" htmlFor="email">
                                                Email
                                            </label>
                                            <input
                                                id="email"
                                                type="text"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="p-3 bg-cinza_claro rounded mb-4"
                                            />
                                            <ErrorMessage name="email" render={(msg) => (
                                                <div className="text-vermelho -mt-3 mb-4">{msg}</div>
                                            )} />
                                            <label className="text-preto font-bold mb-1" htmlFor="cv">
                                                Currículo
                                            </label>
                                            <input
                                                id="cv"
                                                type="file"
                                                onChange={(e) => {setFieldValue("cv", e.target.files[0])}}
                                                onBlur={handleBlur}
                                                className="p-3 bg-cinza_claro rounded mb-4"
                                            />
                                            <ErrorMessage name="cv" render={(msg) => (
                                                <div className="text-vermelho -mt-3 mb-4">{msg}</div>
                                            )} />
                                            <label className="text-preto font-bold mb-1" htmlFor="mensagem">
                                                Mensagem
                                            </label>
                                            <textarea
                                                id="mensagem"
                                                type="text"
                                                value={values.mensagem}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                rows="8"
                                                className="p-3 bg-cinza_claro rounded mb-4 resize-none"
                                            />
                                            <ErrorMessage name="mensagem" render={(msg) => (
                                                <div className="text-vermelho -mt-3 mb-4">{msg}</div>
                                            )} />
                                            <label className="text-preto">
                                                <input
                                                    id="accept"
                                                    type="checkbox"
                                                    checked={values.accept}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="color-black"
                                                />
                                                &nbsp;&nbsp;Estou de acordo com o <a href="/terms" className="font-bold" target="_blank">Termo de Uso de Dados</a>
                                            </label>
                                            <ErrorMessage name="accept" render={(msg) => (
                                                <div className="text-vermelho mt- mb-4">{msg}</div>
                                            )} />
                                            <div className="text-green-700" id="success"></div>
                                            <div className="flex justify-end mt-4">
                                                <button
                                                    className={`px-6 py-2 text-white cursor-pointer ${
                                                        isSubmitting ? "bg-gray-800" : "bg-azul_claro"
                                                    } rounded`}
                                                    type="submit"
                                                    disabled={isSubmitting}>
                                                    Enviar
                                                </button>
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CurriculoPage;
